document
    .querySelectorAll(".ConfirmSubmitModal .closeOfferModal")
    .forEach(function (item, i) {
        item.addEventListener("click", function () {
            document
                .querySelectorAll(".ConfirmSubmitModal")
                .forEach(function (item) {
                    item.style.display = "none";
                });
        });
    });
///////////////////// Open employer compress box  ///////////////////////////

var resumeCompressBoxUnder = document.querySelectorAll(
    ".resumeCompressBox__textUnder"
);
var resumeCompressBoxBtn = document.querySelectorAll(
    ".resumeCompressBox__textUnder span"
);

resumeCompressBoxBtn.forEach(function (item, i) {
    item.addEventListener("click", function () {
        resumeCompressBoxUnder[i].classList.toggle(
            "resumeCompressBox__textUnder__open"
        );
        if (
            resumeCompressBoxUnder[i].classList.contains(
                "resumeCompressBox__textUnder__open"
            )
        ) {
            item.innerHTML = "Скрыть";
        } else {
            item.innerHTML = "Показать";
        }
    });
});

///////////////////// Nav mobil  ///////////////////////////

var mobilMoreBtn = document.querySelector(".mobilMenu__wrap__main span");
var mobilMoreBox = document.querySelector(".mobilMenu__wrap__more");
var mobilMoreBox__close = document.querySelectorAll(
    ".mobilMenu__wrap__more .mobilMenu__wrap__more__wrap"
);

mobilMoreBtn.addEventListener("click", function () {
    mobilMoreBox.classList.toggle("mobilMenu__wrap__more__open");
});
mobilMoreBox__close[1].addEventListener("click", function () {
    mobilMoreBox.classList.toggle("mobilMenu__wrap__more__open");
});

///////////////////// Categories open  ///////////////////////////

var catBoxLength = document.querySelectorAll(".catBox").length;
var catBox = document.querySelectorAll(".catBox")[0];
var catBox__wrap = document.querySelector(".areasOfActivity__wrap");
var ShowAllCat = document.querySelector(".ShowAllCat");
var catToggle = true;
var mathHeightBox;
var mean = 0;

document.addEventListener("DOMContentLoaded", () => {
    function mathHeightBox() {
        document.querySelectorAll(".catBox").forEach(function (item) {
            mean += item.getBoundingClientRect().height;
        });
        mean = mean / catBoxLength;
    }
    mathHeightBox();
    if (window.innerWidth > 901) {
        catBox__wrap.style.height = mean * 2 + 20 + "px";
    }
});

ShowAllCat.addEventListener("click", function () {
    if (catToggle) {
        // catBox__wrap.style.height =
        //     (catBox.getBoundingClientRect().height * catBoxLength) / 3 +
        //     50 +
        //     "px";
        ShowAllCat.innerHTML = "Скрыть";
    } else {
        // catBox__wrap.style.height = mean * 2 + 20 + "px";

        ShowAllCat.innerHTML = "Показать ещё";
    }
    catToggle = !catToggle;
    catBox__wrap.classList.toggle("openCatBox");
});
